@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
  background: linear-gradient(0deg, #242B2B, #242B2B),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.header-logo {
  height: 26px;
  margin-right: 1em;
}
.header-title {
  font-weight: 600;
}
.header-title:hover {
  cursor: pointer;
}
#top-header, #main-drawer  {
  background: #081111;
}
.drawer-item-selected {
  background: linear-gradient(0deg, #242B2B, #242B2B),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.upload-video-pane {
  background: linear-gradient(0deg, #3E3E3E, #3E3E3E),
  linear-gradient(0deg, #242B2B, #242B2B),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.content-feed-thumbnail-container {
  position: relative;
}
.content-feed-thumbnail-status {
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: rgba(0,0,0,0.75) !important;
  border-radius: 8px !important;
}
.content-feed-thumbnail-play-button {
  position: absolute;
  height: 24px;
  width: 24px;
  left: 91px;
  bottom: 46px;
}
.content-feed-thumbnail {
  height: 116px;
}
.clickable-cursor:hover {
  cursor: pointer;
}
.content-feed-container, .video-cell {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.display-name-cell{
  padding-left:0;
  width: 12rem;
  > .display-name-text{
    vertical-align: top;
    margin-left: -1.8em;
    white-space: nowrap;
    display: block;
  }
}
.feed-academy-logo {
  height: 48px;
  width: 48px;
}


.content-feed-right-items {
  display: flex;
  flex-direction: row-reverse;
}
.content-video-feed-row {
  > td {vertical-align: top;}
}

.login-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    display: inline-block;
    margin-top: 1em;
  }
}
.login-page-password, .login-page-email {
  min-width: 300px;
}
.login-page-logo {
 margin-top: 6em;
}
.login-page-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 3em;
}
.login-page-submit {
  border-radius: 2em !important;
  //min-width: 200px !important;
}

div.login-form-fields {
  .MuiInputBase-input {
    margin-left: 2em !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 2em !important;
    min-width: 300px;
  }

  .MuiInputLabel-shrink {
    //margin-left: 6px !important;
  }

  .MuiInputBase-root {
    border-radius: 2em !important;
    min-width: 300px;
    //> input {
    //  margin-left: 2em;
    //
    //}
  }

  .MuiInputLabel-root:not(.MuiInputLabel-shrink) > p.login-page-text-placeholder {
    margin-left: 2em !important;
  }

  .MuiInputLabel-root.MuiInputLabel-shrink > p.login-page-text-placeholder {
    margin-left: 0.5em !important;
  }
}

.media-form-video-type {
  margin-bottom: 1.2em;
}

.media-form-field {
  margin-bottom: 1.2em;
}
.full-screen-review-tool {

  .bigger_width {
    width: 100vw;
    max-width: 100vw;
  }
  .bigger_height {
    height: 100vh;
    max-height: 100vh;
  }
  .bigger_height.wp_ep {
    height: 60vh;
  }
  .bigger_height.wp_el {
    max-width: 100vw;
    max-height: 50vh;
  }
  .bigger_height.wl_ep {
    max-width: 50vw;
    max-height: 100vh;
  }
  .bigger_height.wl_el {
    height: 60vh;
  }
  .bigger_width.wp_ep {
    width: 60vw;
  }
  .bigger_width.wp_el {
    max-width: 100vw;
    max-height: 50vh;
  }
  .bigger_width.wl_ep {
    max-width: 50vw;
    max-height: 100vh;
  }
  .bigger_width.wl_el {
    width: 60vw;
  }


  body {
    margin: 0;
    padding: 0;
    /*background-color: #A2965B;*/
    background-color: rgb(8, 45, 83);
  }

  #player-video-container {
    display: inline-block;
    position: fixed;
    margin: 0;
    padding: 0;
    height: 100vh;
    max-width: 100vw;
  }

  #feedback-video-container {
    display: inline-block;
    position: fixed;
    height: 100vh;
    max-width: 100vw;
    margin: 0;
    padding: 0;
  }


  #pip-video-container {
    display: inline-block;
    position: fixed;
    height: 55vh;
    width: auto;
    top: 0;
    left: 0;
    transition: .22s;
    transition-delay: .11s;
  }

  #pip-selector {
    position: fixed;
    width: 45vw;
    height: 45vh;
    top: 0;
    left: 55vw;
    z-index: 8000;
  }

  #pip-video-container {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    z-index: 8000;
  }

  #pip-video {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 8000;
  }

  #canvas {
    position: fixed;
    top: 7.5vh;
    left: 0;
    height: 92.5vh;
    width: 80vw;
    z-index: 8888;
  }

  #feedback-video {
    position: fixed;
    bottom: 0;
    right: 0;
  }

  #player-video {
    position: fixed;
    bottom: 0;
    right: 0;
    transition: 0.22s;
    height: 100vh;
    max-width: 100vw;
  }

  #player-video.rotate-90 {
    transform-origin: bottom left;
    transform: translateY(-100vw) rotate(90deg);
    object-fit: contain;
    height: 100vw;
    width: 100vh;
  }

  #player-video-container.rotate-90 {
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  #player-video.rotate-270 {
    transform-origin: top right;
    transform: translateX(-100vh) rotate(270deg);
    object-fit: contain;
    height: 100vw;
    width: 100vh;
  }

  #player-video-container.rotate-270 {
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  #webcam-player-container {
    z-index: 888;
    display: inline-block;
    position: absolute;
    height: 38vh;
    width: 38vh;
    padding-top: -6vh;
    padding-right: 3vh;
    top: 0;
    right: 0;
    border-radius: 50%;
    border-color: white;
    border-width: 4px;
    border-style: solid;
    overflow: hidden;
    visibility: hidden;
  }

  .flipx {
    //-webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  #player-video.rotate-180 {
    //-webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  #webcam-player {
    height: 44vh;
    width: 44vh;
    object-fit: cover;
    z-index: 8887;
  }

  #upload-button {
    position: absolute;
    bottom: 200px;
    right: 24vh;
    z-index: 888888;
  }

  #download-link {
    position: absolute;
    bottom: 200px;
    right: 6vh;
    z-index: 888888;
  }

  #begin-button {
    position: absolute;
    bottom: 45vh;
    right: 45vw;
    z-index: 888888;
  }

  #record-button {
    position: absolute;
    bottom: 100px;
    right: 24vh;
    z-index: 888888;
  }

  #countdown-modal {
    color: #9eebcf;
    font-size: 3em;
    position: absolute;
    bottom: 45vh;
    right: 45vw;
    z-index: 888888;
  }

  #recording-dot {
    color: red;
    background-color: red;
    width: 3vh;
    height: 3vh;
    border-radius: 1.5vh;
    font-size: 3em;
    position: absolute;
    bottom: 90vh;
    right: 90vw;
    z-index: 888888;
  }

  #close-button {
    position: absolute;
    bottom: 100px;
    right: 6vh;
    z-index: 888888;


  }

  body {
    overflow: hidden;
  }

  #logo {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 10vh;
    width: 10vh;
    z-index: 888888;
  }

  #player-video.pip-mode {
    position: fixed;
    bottom: 0;
    right: 0;
    transition: 0.22s;
  }

  #moderator-main-pane {
    position: fixed;
    left: 0;
    top: 7.5vh;
    width: 100vw;
    height: 92.5vh;
    background-color: #282828;
  }

  #admin-main-pane {
    position: fixed;
    left: 0;
    top: 7.5vh;
    width: 100vw;
    height: 92.5vh;
    background-color: #282828;
  }

  #coach-main-pane {
    position: fixed;
    left: 0;
    top: 7.5vh;
    width: 80vw;
    height: 92.5vh;
    background-color: #282828;
  }
  #main-pane-display-canvas{
    position: fixed;
    left: 0;
    top: 7.5vh;
    // setting width and height of css will cause
    // grainy video when scaling imageDraw video
    //width: 80vw;
    z-index: 2000;
  }

  #main-pane-canvas{
    position: fixed;
    left: 0;
    top: -1100px;
     width: 1920px;
     height: 1080px;
  }
  #main-pane-display-canvas.hide-for-clip{
    z-index: -1000;
    display: none;
  }

  #title-bar{
    position: fixed;
    //padding: 24px;
    right: 0;
    width: 100vw;
    height: 7.5vh;
    background-color: #081111;
    display:flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
  }

  .mainSidePanel {
    position: fixed;
    right: 0;
    top: 7.5vh;
    width: 20vw;
    height: 75vh;
    //overflow-y: auto;
    background-color: #282828;
    display: flex;
    flex-direction: column;
  }

  #main-pane-video {
    position: fixed;
    left: 0;
    top: 7.5vh;
    width: 100%;
    height: 100%;
    max-width: 80vw;
    max-height: 72.5vh;
    background-color: #000;
  }

  #main-pane-video.recording {
    max-width: 100vw;
    max-height: 90vh;
  }

  .video-controls {
    position: fixed;
    left:0;
    bottom: 0;
    height: 17.5vh;
    width: 100vw;
    background-color: #242B2B;
  }

  .video-controls-top-row {
    display: flex;
    position: relative;
    justify-items: center;
    justify-content: center;
  }

  .video-controls-top-row > span:first-child {
    position: absolute;
    left: 1em;
    top: 0.5em;
  }

  .video-controls.recording {
    bottom: 0;
    width: 100vw;
  }

  .video-controls > button {
    height: 100%;
    padding-left: 4em;
    padding-right: 4em;
    display: inline-block;
  }

  #save-clip-button {
  }

  #forward-stage-button > button {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 10vh;
    width: 20vw;
  }

  #previous-stage-button > button {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 10vh;
    width: 20vw;
  }
  #stage-controls {
    background: #9eebcf;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 10vh;
  }

  .playButton {
    width: 10vw;
  }
  .review-point-clip.selected {
    background: #ff7f504d;
  }
  .review-point-clip {
    padding-top: 1em;
    min-height: 2em;
  }

  #review-tool-close-button {
    //position: absolute;
    //top: 0;
    //right: 0;
    color: #999999;
    padding: 0.25em;
    border-radius: 2.5em;
    //background-color: #3E3E3E;
    min-width: 0;
    z-index: 10000;
  }

  .recording-status-component {
    min-height: 10vh;
  }

  .recorded-clip-button{
    padding: 0.3em;
    button{
      border-color: #999999;
      color: #EEEEEE;
      border-width: 2px;
      padding: 0.75em 0.2em;
      width: 100%;
      justify-content: left;
      .MuiSvgIcon-root {
        margin-right: 0.3em;
      }
      text-transform: none !important;
      padding-left: 0.5em;
    }
    button.recording{
      border-color: #999999;
      color: #EEEEEE;
    }
    button.empty{
      border-style: dashed !important;
      border-color: #3E3E3E;
      color: #999999;
    }
  }
  .recorded-clip-button.loaded {
    button {
      border-color: #BA840C;
      color: #BA840C;
    }
    button.loaded-left{
      border-right-width: 1px !important;
      border-right-color: #000000 !important;
    }
    button.loaded-right{
      border-left-width: 1px !important;
      border-left-color: #3E3E3E !important;
      border-bottom-left-radius: 1px;
      border-top-left-radius: 1px;
    }
  }
  .recorded-clip-button.confirm {
    justify-content: center;
    border-width: 0;
  }
  .recorded-clip-button.confirm-delete {
    background-color: black;
    color: red !important;
  }
  .recorded-clip-button.confirm-cancel {
    background-color: black;
    color: #999999 !important;
    border-left-width: 1px !important;
    border-left-color: #999999 !important;
  }
  .recorded-clip-button.submission-video{
    button{
      justify-content: center;
    }
  }
  .submission-video > button > svg { font-size: 2.2em}
  .recorded-clip-button.play-all-button{
    button{
      justify-content: center;
    }
  }
  .play-all-button > button > svg { font-size: 2.2em}
  .side-panel-divider{
    border-top: 1px solid #081111;
    color: #3E3E3E;
    margin-left: 4.8px;
    margin-right: 4.8px;
  }
  .recorded-clips {
    position: relative;
  }
  .recorded-clips > .side-panel-bottom {
    position: fixed;
    bottom: 0;
    z-index: 10;
  }
  .side-panel-fixed-top {
    flex-shrink: 0;
    height: auto;
  }
  .side-panel-scrollable {
    flex-grow: 1;
    overflow-y: auto;
  }

  .side-panel-fixed-bottom {
    flex-shrink: 0;
    height: auto;
  }
 //.drawing-toolbox > button > svg {
 //   color: #EEEEEE !important;
 //   background-color: transparent;
 // }
 // .drawing-toolbox > button.drawing-button {
 //   color: #EEEEEE;
 // }
 // .drawing-toolbox > button.drawing-button.selected {
 //   color: #BA840C;
 // }
  .drawing-toolbox.disabled > button > svg {
    color: #999999;
  }
}
.submission-details-title{
  display: flex;
  align-items: center;
}
.submission-details {
  position: fixed;
  top: 7.5vh;
  left: 0;
  width: 100vw;
  min-height: 20vh;
  z-index: 1000;
  background-color: #1E1E1E;
  display: flex;
  > div {
    padding: 2em;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    > div {
      margin-bottom: 1em;
      display: flex;
      flex-direction: row;
    }
  }
}
.submission-detail-label {
  color: #999999;
  min-width: 100px;
}
.username-toggle {
  cursor: pointer;
}
.title-bar-right {
  display: flex;
  > div, > span, > button {
    margin-right: 0.8em;
  }
}
.reject-button {
  color: #999999 !important;
}
.reject-confirm-button {
  border-color: red !important;
  color: red !important;
  border-width: 2px !important;
  border-style: solid !important;
}
.reject-confirm-button-disabled {
  color: #3E3E3E !important;
  border-color: red !important;
  border-width: 2px !important;
  border-style: solid !important;
}
.reject-cancel-button {
  color: #3E3E3E !important;
}
.confirm-modal-background {
  background-color: #1E1E1E !important;
}
.video-main-pane-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.main-content-box {
  width: 100%;
}

.side-panel-up-down-button-group{
  display: flex;
  align-items: center;

}
.side-panel-up-down-button{
  height: 1.6em;
  width: 1.6em;
  border-style: solid;
  border-color: #999999;
  color: #EEEEEE;
  border-width: 2px;
  border-radius: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
.side-panel-up-down-button:hover {
  cursor: pointer;
  background-color: rgba(186, 132, 12, 0.08);
}
.hide-issue-textarea {
  display:none;
}
.confirm-modal {
  z-index: 10000;
}
.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.1 !important;
}